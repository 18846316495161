<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap">
      <img class="banner__img thumbnails__img" src="../assets/img/banners/customer-banner.jpg" alt="">
    </div>
    <div class="thumbnails__textWrap">
      <h1 class="titlePage">Welcome to starline security <br> <strong class="titlePage_accent">customer system</strong></h1>
      <span class="text text_green text_center text_italic">Please make a selection...</span>
      <div class="thumbnailsTable thumbnailsTable_list">
        <router-link :to="item.link" class="thumbnailsTable__item thumbnailsTable__item_2" v-for="item in items">
          <img :src="item.img" :alt="item.text" class="thumbnailsTable__img">
          <span class="thumbnailsTable__text">{{item.text}}</span>
        </router-link>
      </div>
      <div class="divider">
        <span class="divider__text text_green">or</span>
      </div>
      <router-link :to="home" class="btn btn_default btn_full">Go back to main screen</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Consumer',
  data() {
    return {
      home: '/',

      items: [
        {
          img: require('../assets/img/png/new-estimate.png'),
          text: 'Create a New Estimate',
          link: '#',
        },
        {
          img: require('../assets/img/png/estimate.png'),
          text: 'Retrive Existing Estimate',
          link: '#',
        },
        {
          img: require('../assets/img/png/dealer.png'),
          text: 'Find a Dealer',
          link: '#',
        },
        {
          img: require('../assets/img/png/contact.png'),
          text: 'Contact Starline Security',
          link: '#',
        },
      ],

    };
  },
  computed: {
    // items() {
    //   return this.$store.getters['consumer/items'];
    // },
  },
};
</script>

<style scoped lang="scss">

  @import '../assets/scss/utils/vars';

  .text{
    display: none;
  }

  @media screen and (max-width: $md){
    .text{
      display: block;
    }
  }

</style>
